.contact-1{
  size: 100%;
  height: 120vh;
  z-index: 1;
 

}

.star{
  height: 100vh;
  z-index: 1;
}

.earth{
  position: absolute;
  width: 30%;
  aspect-ratio: 0.75/1;
  border-radius: 2rem;
  

  display: grid;
  place-items: center;

}