.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover{
    border-color: var(--color-primary-varient);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

